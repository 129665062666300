<div *ngIf="modal" class="stack-modal modal" [ngClass]="{'stack-modal--open show': modal.status === ModalStatus.OPEN, 'stack-modal--closed': (modal.status === ModalStatus.CLOSED)}">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-frame modal-fullscreen">
    <div class="modal-content">
      <div class="stack-modal-controls">
        <div class="stack-modal-control">
          <button type="buttom" (click)="hide()" class="button button-icon">
            <i class="fic-minus"></i>
          </button>
        </div>
        <div class="stack-modal-control">
          <button type="buttom" (click)="beforeClose()" class="button button-icon">
            <i class="fic-close"></i>
          </button>
        </div>
      </div>
      <ng-container [appDynamicComponents]="modal.id"></ng-container>
    </div>
  </div>
</div>
