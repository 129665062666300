import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoggerService } from "@services/logger-service.service";
import { map, switchMap, take } from "rxjs/operators";
import { HttpClientAppService } from "@services/http-client-app.service";

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  readonly PATH_ACCESS_TOKEN: string = "/get-access-token";

  acceptablePaths: string[] = [
    "ws/hs/armcb/files/",
  ];

  constructor(private logger: LoggerService,
              private httpClientApp: HttpClientAppService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isUrlAcceptable(request.url)) {
      return this.httpClientApp.get(this.httpClientApp.GO_AUTH_ADMIN_SIGN + this.PATH_ACCESS_TOKEN).pipe(
        take(1),
        map((response: { access_token: string }) => response.access_token),
        switchMap((token: string) => {
          const clonedRequest: HttpRequest<any> = request.clone({
            setHeaders: {
              Authorization: `Bearer ${ token }`
            }
          });
          return next.handle(clonedRequest);
        })
      );
    }
    return next.handle(request);
  }

  private isUrlAcceptable(url: string): boolean {
    let isAccept: boolean = false;
    this.acceptablePaths.forEach((path: string) => {
      if (url.includes(path)) {
        isAccept = true;
      }
    });
    return isAccept;
  };
}
