import { Component, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { StackingModal } from "@share/modules/stacking-modals/models/stacking-modal.model";
import { StackingModalsService } from "@share/modules/stacking-modals/services/stacking-modals.service";
import { LoggerService } from "@app/_core/services/logger-service.service";
import { Observable } from "rxjs";
import { StackingModalComponent } from "@share/modules/stacking-modals/components/stacking-modal/stacking-modal.component";
import { AsyncPipe, NgForOf, NgIf } from "@angular/common";

@Component({
  selector: "app-stacking-modals",
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: "./stacking-modals.component.html",
  imports: [
    StackingModalComponent,
    AsyncPipe,
    NgForOf,
    NgIf
  ],
  styleUrls: ["./stacking-modals.component.scss"]
})
export class StackingModalsComponent {
  modals$: Observable<StackingModal[]>;

  constructor(private modalsService: StackingModalsService,
              private loggerService: LoggerService) {
    this.loggerService.debug(this.constructor.name + " is created.");
    this.modals$ = this.modalsService.modals$;
  }

}
