import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { LoggerService } from "@services/logger-service.service";
import { NavigationStart, Router } from "@angular/router";
import { tap } from "rxjs";
import { UserService } from "@services/user.service";
import { switchMap } from "rxjs/operators";
import { User } from "@core/models/user.model";
import { IconComponent } from "@share/components/icon/icon.component";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-support",
  standalone: true,
  template: `
    <div class="support" *ngIf="isActive">
      <a [href]="href" class="support__link support__link--animated" (mouseenter)="hoverLink()" #supportLink>
        <span class="support-link-icon">
          <app-icon name="operator"></app-icon>
        </span>
        <span class="support-link-title">
          Написать в поддержку
        </span>
      </a>
    </div>
  `,
  styleUrls: ["./support.component.scss"],
  imports: [
    IconComponent,
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportComponent implements OnInit {
  @ViewChild("supportLink") supportLink: ElementRef;
  public href: string = "mailto:support.cbu@it.mos.ru?subject=Обращение%20с%20Портала";
  public isActive: boolean = false;
  private userIsAdded: boolean = false;

  constructor(private logger: LoggerService,
              private renderer: Renderer2,
              private router: Router,
              private cd: ChangeDetectorRef,
              private userService: UserService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  ngOnInit() {
    this.router.events
      .pipe(
        tap(event => {
          if (event instanceof NavigationStart) {
            this.isActive = !event.url.includes("/1c/");
            this.href += "&body=%0D%0A%0D%0A%20%0D%0AДополнительная инфрмация%20для%20тех.%20поддержки:%20%0D%0Apath:%20" + event.url;
            this.userIsAdded = false;
            this.cd.markForCheck();
          }
        }),
        switchMap(() => this.userService.profile$),
        tap((profile: User) => {
          if (profile && !this.userIsAdded) {
            this.href += "%0D%0Aпользователь:%20" + profile.user;
            this.userIsAdded = true;
            this.cd.markForCheck();
          }
        })
      )
      .subscribe();
  }

  hoverLink() {
    this.renderer.removeClass(this.supportLink.nativeElement, "support__link--animated");
    this.cd.markForCheck();
  }
}
