import { Injectable } from "@angular/core";
import { LoggerService } from "@services/logger-service.service";
import { HttpClientAppService } from "@services/http-client-app.service";
import { ExceptionService } from "@services/exception.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { UserStatistic } from "@core/models/user-statistic.interface";
import { HttpParams } from "@angular/common/http";
import { UserStatisticRequest, userStatisticRequestLimit, userStatisticRequestSortBy } from "@core/models/user-statistic.request";
import { Params, Router } from "@angular/router";
import { UserStatisticOrderBy } from "@core/models/user-statistic-order-by.enum";

@Injectable()
export class StatisticService {
  readonly PATH_STATISTIC_USERS: string = "/v3/statistic/users";

  private usersStatisticSource: BehaviorSubject<UserStatistic[]> = new BehaviorSubject<UserStatistic[]>([]);
  public usersStatistic$: Observable<UserStatistic[]> = this.usersStatisticSource.asObservable();

  private requestSource: BehaviorSubject<UserStatisticRequest> = new BehaviorSubject<UserStatisticRequest>(new UserStatisticRequest());
  public request$: Observable<UserStatisticRequest> = this.requestSource.asObservable();

  constructor(private logger: LoggerService,
              private httpClientApp: HttpClientAppService,
              private exception: ExceptionService,
              private router: Router) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  public getRequest(): UserStatisticRequest {
    return this.requestSource.getValue();
  }

  public setRequest(request: UserStatisticRequest): void {
    this.requestSource.next(request);
  }

  public getByUsers(): Observable<UserStatistic[]> {
    const request: UserStatisticRequest = this.requestSource.getValue();
    let params: HttpParams = new HttpParams();
    params = params.set("limit", request.limit);
    params = params.set("offset", request.offset);
    if (request.sortBy) {
      params = params.set("sort_by", request.sortBy);
      if (request.orderBy && request.orderBy === UserStatisticOrderBy.DESC) params = params.set("sort_by", "-" + request.sortBy);
    }
    if (request.q) params = params.set("q", request.q);

    if (request.from && request.to) {
      params = params.set("from", request.from);
      params = params.set("to", request.to);
    }

    return this.httpClientApp.get<UserStatistic[]>(this.httpClientApp.GO_SIGN + this.PATH_STATISTIC_USERS, params)
      .pipe(
        tap((usersStatistic: UserStatistic[]) => this.usersStatisticSource.next(usersStatistic)),
        catchError(err => {
          this.exception.handleRequestErrorInfo(err);
          return of(null);
        })
      );
  }

  public navigateWithParams(commands: string[] = [], onSameUrlNavigation: "reload" | "ignore" = "reload"): void {
    this.router.onSameUrlNavigation = onSameUrlNavigation;
    const params: Params = {};

    const request: UserStatisticRequest = this.requestSource.getValue();

    if (request.sortBy !== userStatisticRequestSortBy) {
      params.sortBy = request.sortBy;
    }

    if (request.orderBy) {
      params.orderBy = request.orderBy;
    }

    if (request.limit !== userStatisticRequestLimit) {
      params.limit = request.limit;
    }

    if (request.q) {
      params.q = request.q;
    }

    if (request.from && request.to) {
      params.from = request.from;
      params.to = request.to;
    }

    this.router.navigate(commands, {
      queryParams: params
    })
  }
}
