import {Injectable} from "@angular/core";
import {LoggerService} from "@services/logger-service.service";

@Injectable()
export class ExtraFunctionsService {

  constructor(private logger: LoggerService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  range(start, end) {
    if (start <= end) return Array.from({length: (end - start + 1)}, (v, k) => k + start);
    return [];
  }
}
