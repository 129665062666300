import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { LoggerService } from "@services/logger-service.service";
import { ExceptionService } from "@services/exception.service";
import { HttpClientAppService } from "@services/http-client-app.service";
import { BaseAuthInterceptor } from "@core/interceptors/base-auth.interceptor";
import { HttpErrorInterceptor } from "@core/interceptors/error.interceptor";
import { AccessTokenInterceptor } from "@core/interceptors/access-token.interceptor";
import { ToastrModule } from "ngx-toastr";
import { AppSettingsService } from "@services/app-settings.service";
import { AuthGuard } from "@core/guards/auth.guard";
import { CookieService } from "@services/cookie.service";
import { ExtraFunctionsService } from "@services/extra-functions.service";
import { FileService } from "@services/file.service";
import { LoaderService } from "@services/loader.service";
import { LoginGuard } from "@core/guards/login.guard";
import { MenuGuard } from "@core/guards/menu.guard";
import { ScriptInjectorService } from "@services/script-injector.service";
import { SwitcherDarkLightService } from "@services/switcher-dark-light.service";
import { TemplatingService } from "@services/templating.service";
import { UrlService } from "@services/url.service";
import { UserService } from "@services/user.service";
import { EventsService } from "@services/events.service";
import { NewsService } from "@services/news.service";
import { StatisticService } from "@services/statistic.service";
import { CompaniesService } from "@services/companies.service";
import { NGX_LOCAL_STORAGE_CONFIG } from "ngx-localstorage";


@NgModule({
  declarations: [],
  exports: [
    ToastrModule
  ],
  imports: [
    ToastrModule.forRoot()
  ],
  providers: [
    AppSettingsService,
    CookieService,
    ExceptionService,
    ExtraFunctionsService,
    FileService,
    HttpClientAppService,
    LoaderService,
    LoggerService,
    ScriptInjectorService,
    SwitcherDarkLightService,
    TemplatingService,
    UrlService,
    UserService,
    EventsService,
    NewsService,
    StatisticService,
    CompaniesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true
    },
    {
      provide: NGX_LOCAL_STORAGE_CONFIG,
      useValue: {
        prefix: "demo",
        delimiter: "@"
      }
    },
    AuthGuard,
    LoginGuard,
    MenuGuard,
    provideHttpClient(
      withInterceptorsFromDi()
    )
  ]
})
export class CoreModule {
}
