import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from "@angular/core";
import {LoaderService} from "@services/loader.service";
import {Observable} from "rxjs";
import {LoggerService} from "@services/logger-service.service";
import { LoaderComponent } from "@share/components/loader/loader.component";
import { AsyncPipe, NgIf } from "@angular/common";

@Component({
  selector: "app-route-loading",
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <ng-container *ngIf="loading$ | async as loading">
      <div class="page-loader" *ngIf="loading">
        <app-loader></app-loader>
      </div>
    </ng-container>
  `,
  styleUrls: ["./route-loading.component.scss"],
  imports: [
    LoaderComponent,
    AsyncPipe,
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteLoadingComponent implements OnInit {

  loading$: Observable<boolean>;

  constructor(private logger: LoggerService,
              private loaderService: LoaderService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  ngOnInit() {
    this.loading$ = this.loaderService.loading$;
  }
}
