import {Inject, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {LoggerService} from "@services/logger-service.service";

@Injectable()
export class TemplatingService {
  private renderer: Renderer2;

  constructor(private logger: LoggerService,
              @Inject(DOCUMENT) private document: Document,
              rendererFactory: RendererFactory2) {
    this.logger.debug(this.constructor.name + " is created.");
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setClass(className: string, el: any | "body" = this.document.documentElement): void {
    if(el === "body") el = this.document.body;
    if (className) this.renderer.addClass(el, className);
  }

  removeClass(className: string, el: any | "body" = this.document.documentElement): void {
    if(el === "body") el = this.document.body;
    if (className) this.renderer.removeClass(el, className);
  }
}
