import { NgModule } from "@angular/core";
import { StackingModalsComponent } from "./components/stacking-modals/stacking-modals.component";
import { StackingModalComponent } from "./components/stacking-modal/stacking-modal.component";

@NgModule({
  imports: [StackingModalsComponent, StackingModalComponent],
  exports: [StackingModalComponent, StackingModalsComponent]
})
export class StackingModalsModule {
}
