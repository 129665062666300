import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnDestroy, OnInit } from "@angular/core";
import { FADE_ANIMATION } from "@share/animations";
import { StackingModal } from "@share/modules/stacking-modals/models/stacking-modal.model";
import { StackingModalsService } from "@share/modules/stacking-modals/services/stacking-modals.service";
import { LoggerService } from "@app/_core/services/logger-service.service";
import { Subject } from "rxjs";
import { StackingModalStatus } from "@share/modules/stacking-modals/models/stacking-modal-status.enum";
import { DynamicComponentsService } from "@app/_share/modules/dynamic-components/dynamic-components.service";
import { NgClass, NgIf } from "@angular/common";
import { DynamicComponentsDirective } from "@share/modules/dynamic-components/dynamic-components.directive";

@Component({
  selector: "app-stacking-modal",
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: "./stacking-modal.component.html",
  styleUrls: ["./stacking-modal.component.scss"],
  imports: [
    NgClass,
    DynamicComponentsDirective,
    NgIf
  ],
  animations: [FADE_ANIMATION]
})
export class StackingModalComponent implements OnInit, OnDestroy {
  @Input() modal: StackingModal;

  private isDestroyed$: Subject<void> = new Subject();

  public ModalStatus: typeof StackingModalStatus = StackingModalStatus;

  constructor(private loggerService: LoggerService,
              private stackingModalsService: StackingModalsService,
              private dynamicComponentsService: DynamicComponentsService) {
    this.loggerService.debug(this.constructor.name + " is created.");
  }

  ngOnInit(): void {
    this.dynamicComponentsService.addComponent(this.modal.id, this.modal.componentType, { ...this.modal.componentData, modal: this.modal })
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  public hide(): void {
    this.stackingModalsService.hideModal(this.modal.id);
  }

  public beforeClose(): void {
    this.stackingModalsService.closeModal(this.modal.id);
    this.dynamicComponentsService.clearComponents(this.modal.id);
    this.stackingModalsService.destroyModal(this.modal.id);
  }

  public close(event: boolean): void {
    if (event) {
      this.stackingModalsService.destroyModal(this.modal.id);
    }
  }

}
