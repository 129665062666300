import {animate, state, style, transition, trigger} from "@angular/animations";

export const FADE_ANIMATION_DURATION: number = 400;

export const FADE_ANIMATION = trigger("fade", [
  state("in", style({opacity: 1})),
  state("out", style({opacity: 0})),
  transition(":enter", [
    style({opacity: 0}),
    animate(FADE_ANIMATION_DURATION)]
  ),
  transition("in=>out", animate(FADE_ANIMATION_DURATION))
]);
