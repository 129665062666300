import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {EMPTY, Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {LoggerService} from "@services/logger-service.service";
import {ExceptionService} from "@services/exception.service";
import {Router} from "@angular/router";
import {LoaderService} from "@services/loader.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private logger: LoggerService,
              private router: Router,
              private exceptionService: ExceptionService,
              private loaderService: LoaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        const httpError: HttpErrorResponse = err;

        if (httpError.status === 403 || httpError.status === 401) {
          this.router.navigate(["/login"]);
          return throwError(() => err);
        } else {
          this.exceptionService.raiseUnknownError(`An HTTP request error has occurred. Code ${httpError.status}. Description: ${httpError.message}`, httpError);
        }

        if (typeof httpError.error["code"] !== "undefined") {
          return throwError(() => httpError.error);
        }

        if (httpError.status === 404 || httpError.status === 505) {
          this.loaderService.hide();
        }
      }
      return throwError(() => err);
    }));
  }
}
