import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Route, UrlSegment } from "@angular/router";
import {Observable, of} from "rxjs";
import {LoggerService} from "@services/logger-service.service";
import {UserService} from "@services/user.service";
import {User, UserResponsibilityMenuItem} from "@core/models/user.model";
import {map} from "rxjs/operators";
import {SidebarMenuItem} from "@core/models/sidebar-menu-item.enum";

@Injectable()
export class MenuGuard  {

  constructor(private logger: LoggerService,
              private userService: UserService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.isActiveMenuItem(route.url);
  }

  private isActiveMenuItem(urlSegment: UrlSegment[]): Observable<boolean> {
    return this.userService.profile$
      .pipe(
        map((user: User) => {
          return user?.responsibility?.menu?.find(
            (item: UserResponsibilityMenuItem) => {
              return SidebarMenuItem.getPath(item.name) == urlSegment[0].path;
            }
          ).visibility;
        })
      )
  }
}
