import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoggerService } from "@services/logger-service.service";
import { Subject } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { AppSettingsService } from "@services/app-settings.service";
import { Title } from "@angular/platform-browser";
import { environment } from "@env/environment";
import { PrimeNGConfig } from "primeng/api";
import { Translation } from "primeng/api/translation";
import { SidebarMenuItem } from "@core/models/sidebar-menu-item.enum";

@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
    <app-route-loading></app-route-loading>
    <app-support></app-support>
    <!-- TODO: Auto-creating when module is imported -->
    <app-stacking-modals></app-stacking-modals>
  `,
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  private isDestroyed$: Subject<void> = new Subject();
  private primeNgTranslationRu: Translation = require("assets/prime-ng-translation.ru.json");

  constructor(private logger: LoggerService,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private appSettingsService: AppSettingsService,
              private config: PrimeNGConfig
  ) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  ngOnInit() {
    this.config.setTranslation(this.primeNgTranslationRu);

    this.titleService.setTitle(environment.title);
    this.router.events
      .pipe(
        tap(event => {
          if (event instanceof NavigationEnd) {
            const currentState: { [p: string]: string } = this.router.getCurrentNavigation().extras.state;
            const isStatic: boolean = !!(this.route.root.firstChild.snapshot.data["static"] || currentState?.static)
            this.appSettingsService.changeStatic(isStatic);
            // Fix: hide only on homepage v2
            this.appSettingsService.setCustomSetting("isPageHeaderShown", !isStatic);
            this.appSettingsService.setCustomSetting("isPageHeaderShown", !event.url.includes("/" + SidebarMenuItem.getPath(SidebarMenuItem.dataQueries)));
            this.appSettingsService.setCustomSetting("isWithMaxWidth", event.url.includes("/" + SidebarMenuItem.getPath(SidebarMenuItem.webinars)));

            const searchPathStrings: string[] = [SidebarMenuItem.getPath(SidebarMenuItem.dataQueries), SidebarMenuItem.getPath(SidebarMenuItem.webinars)];
            const regexPattern: RegExp = new RegExp(searchPathStrings.join("|"), "i");
            this.appSettingsService.setCustomSetting("isSidebarShown", !regexPattern.test(event.url));
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
