import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { CoreModule } from "@core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ShareModule } from "@share/share.module";
import { StackingModalsModule } from "@share/modules/stacking-modals/stacking-modals.module";
import { registerLocaleData } from "@angular/common";
import localeRu from "@angular/common/locales/ru";
import { RouteLoadingComponent } from "@share/components/route-loading/route-loading.component";
import { SupportComponent } from "@share/components/support/support.component";
import { provideScrollbarOptions } from "ngx-scrollbar";

registerLocaleData(localeRu);

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ShareModule,
    StackingModalsModule,
    RouteLoadingComponent,
    SupportComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "ru" },
    provideScrollbarOptions({
      visibility: "hover",
    })
  ]
})
export class AppModule {
}
