import { UserStatisticSortBy } from "@core/models/user-statistic-sort-by.enum";
import { UserStatisticOrderBy } from "@core/models/user-statistic-order-by.enum";

export const userStatisticRequestLimit: number = 25;
export const userStatisticRequestSortBy: UserStatisticSortBy = UserStatisticSortBy.total;
export const userStatisticRequestOrderBy: UserStatisticOrderBy = UserStatisticOrderBy.ASC;

export class UserStatisticRequest {
  limit: number = userStatisticRequestLimit;
  offset: number = 0;
  sortBy?: UserStatisticSortBy = userStatisticRequestSortBy;
  orderBy?: UserStatisticOrderBy = userStatisticRequestOrderBy;
  from?: string;
  to?: string;
  q?: string;
}
