import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "@app/main-site/components/not-found/not-found.component";


export const routes: Routes = [
  {
    path: "",
    redirectTo: "/v2",
    pathMatch: "full"
  },
  {
    path: "login",
    loadChildren: () => import("@app/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "v2",
    children: [
      {
        path: "1c",
        loadChildren: () => import("@app/popup-windows/popup-windows.module").then(m => m.PopupWindowsModule)
      },
      {
        path: "",
        loadChildren: () => import("@app/main-site/main-site.module").then(m => m.MainSiteModule)
      }
    ]
  },
  {
    path: "tasks",
    redirectTo: "/v2/tasks/1?sortBy=date&order=desc",
    pathMatch: "full"
  },
  {
    path: "profile",
    redirectTo: "/v2/profile",
    pathMatch: "full"
  },
  {
    path: "viewer",
    loadChildren: () => import("@app/viewer/viewer.module").then(m => m.ViewerModule)
  },
  {
    // @deprecated
    path: "tp",
    redirectTo: "/v2/processes",
    pathMatch: "full"
  },
  {
    // @deprecated
    path: "tp/docs",
    redirectTo: "/v2/processes/old/docs",
    pathMatch: "full"
  },
  {
    // @deprecated
    path: "tp/:guid",
    redirectTo: "/v2/processes/:guid",
    pathMatch: "full"
  },
  {
    path: "not-found",
    component: NotFoundComponent
  },
  {
    path: "**",
    component: NotFoundComponent,
    data: {
      "breadcrumb": "Ошибка 404"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
