export enum UserStatisticSortBy {
  userName = "userName",
  done = "done",
  new = "new",
  overdue = "overdue",
  total = "total",
  inProgress = "inProgress",
  incorrect = "incorrect",
  averageTime = "averageTime"
}

export namespace UserStatisticSortBy {
  export function getValue(): UserStatisticSortBy[] {
    return userStatisticSortByData.map((data: UserStatisticSortByData) => data.value);
  }

  export function getName(value: UserStatisticSortBy): string {
    return userStatisticSortByData.find((data: UserStatisticSortByData) => data.value === value)?.displayName;
  }

  interface UserStatisticSortByData {
    value: UserStatisticSortBy;
    displayName: string;
  }

  const userStatisticSortByData: UserStatisticSortByData[] = [
    { value: UserStatisticSortBy.userName, displayName: "по имени" },
    { value: UserStatisticSortBy.done, displayName: "завершенные" },
    { value: UserStatisticSortBy.new, displayName: "новые" },
    { value: UserStatisticSortBy.overdue, displayName: "просроченные" },
    { value: UserStatisticSortBy.total, displayName: "всего" },
    { value: UserStatisticSortBy.inProgress, displayName: "в процессе" },
    { value: UserStatisticSortBy.incorrect, displayName: "с ошибкой" },
    { value: UserStatisticSortBy.averageTime, displayName: "среднее время" },

  ];
}
