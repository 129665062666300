import {Injectable} from "@angular/core";
import { Router } from "@angular/router";
import {Observable} from "rxjs";
import {LoggerService} from "@services/logger-service.service";
import {UserService} from "@services/user.service";
import {User} from "@core/models/user.model";
import {map} from "rxjs/operators";

@Injectable()
export class LoginGuard  {

  constructor(private logger: LoggerService,
              private userService: UserService,
              private router: Router) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  canActivate(): Observable<boolean> {
    return this.userService.loadProfile()
      .pipe(
        map((user: User) => {
          if (user) {
            this.router.navigate(["/"])
          }
          return !user;
        })
      );
  }
}
