import {Injectable} from "@angular/core";
import { Route, Router } from "@angular/router";
import {Observable} from "rxjs";
import {LoggerService} from "@services/logger-service.service";
import {UserService} from "@services/user.service";
import {User} from "@core/models/user.model";
import {map} from "rxjs/operators";

@Injectable()
export class AuthGuard  {

  constructor(private logger: LoggerService,
              private router: Router,
              private userService: UserService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  canActivate(): Observable<boolean> {
    return this.isAuthenticated();
  }

  canLoad(route: Route): Observable<boolean> {
    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean> {
    return this.userService.profile$
      .pipe(
        map((user: User) => !!user)
      );
  }
}
